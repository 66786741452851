import React, {useState} from 'react';
import {motion} from "framer-motion";
import {fadeIn} from "../variants";
import { toast, Toaster } from 'react-hot-toast';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formStatus, setFormStatus] = useState(null);
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // Basic form validation
    if (!formData.name || !formData.email || !formData.message) {
      // If any of the required fields are missing, show an error message
      setFormStatus('error');
      toast.error('Please fill in all the required fields.');
      return;
    }
  
    try {
      // Your actual form submission logic using the fetch API
      const response = await fetch('https://formspree.io/f/xzbnpzeg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        // Form submitted successfully
        setFormStatus('success');
        // toast.success('Form submitted successfully!');
        toast('Email Sent Successfully!',
        {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
      }
        );
        // Reset form data to initial values
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        // Form submission failed
        setFormStatus('error');
        toast.error('Form submission failed.');
      }
    } catch (error) {
      // Handle any errors during form submission
      console.error('Form submission failed:', error);
      setFormStatus('error');
      toast.error('Form submission failed.');
    }
  };    

  return (
  <section className='py-16 lg:section' id='contact'>
    <div className='container mx-auto'>
      <div className='flex flex-col lg:flex-row'>
        <motion.div variants={fadeIn('right',0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}}  className='flex-1 flex justify-start items-center'>
          <div>
            <h4 className='text-xl uppercase text-accent font-medium mb-2 trackingwide'>Get in Touch.</h4>
            <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>Let's work <br />together</h2>
          </div>
        </motion.div>
        <motion.form onSubmit={handleFormSubmit} variants={fadeIn('left',0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}} className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'>
          <input 
            className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
            type='text'
            placeholder='Youe Name'
            name='name'
            onChange={handleInputChange}
            value={formData.name} // Add this line
          />
          <input 
            className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
            type='text'
            placeholder='Your Email'
            name='email'
            onChange={handleInputChange}
            value={formData.email} // Add this line
          />
          <textarea 
            className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
            placeholder="Your Message"
            name='message'
            onChange={handleInputChange}
            value={formData.message} // Add this line
          >  
          </textarea>
          <button className='btn btn-lg'>Send Message</button>
        </motion.form>
      </div>
      {/* {formStatus && (
          <div className={`text-left font-bold mt-4 ${formStatus === 'success' ? 'text-green-500' : 'text-red-500'}`}>
            {formStatus === 'success' ? 'Form submitted successfully!' : 'Form submission failed. Please try again.'}
          </div>
      )} */}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </div>  
  </section>
  );
};

export default Contact;
