import React from 'react';
import { BsArrowUpRight } from 'react-icons/bs';
import {motion} from "framer-motion";
import { fadeIn } from '../variants';

const services = [
  {name: "Front End Development",description: "Good in building web and mobile applications with expertise in React JS, React Native JS, HTML, CSS / Tailwind CSS & Material UI"},
  {name: "Back End Development",description: "Good in building backend with expertise in Express JS,Node JS,Python & Mongo DB"},
  {name: "Quality Assurance",description: "Good in creating Test Automation framework for Web,Mobile and Desktop applications. Expertise in Appium,Selenium,Playwright,Rest Assured API Automation,Webdriver IO,HP UFT and Puppeteer JS."},
  // {name: "Devops",  description: "Expertise in creating and managing CI/CD pipelines, and integrating testing and monitoring into the pipeline. Strong understanding of infrastructure as code (IaC), containerization (Docker, Kubernetes), and cloud platforms (AWS, Azure, GCP)."}
]
const Services = () => {
  return (
  <section className='section' id='services'>
    <div className='container mx-auto'>
      <div className='flex flex-col lg:flex-row'> 
        <motion.div variants={fadeIn('right',0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}} className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'>
          <h2 className='h2 text-accent mb-6'>What I Do.</h2>
          <h3 className='h3 max-w-[455px] mb-16'>
            I'm a Full Stack Software Engineer with over 9 years of IT industry experience
          </h3>
          <a href='https://github.com/athulshere' target="_blank">
          <button className='btn btn-sm'>See My Work</button>
          </a>
        </motion.div>
        <div>
          <motion.div variants={fadeIn('left',0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}} className='flex-1'>
            {services.map((service,index)=>{
              const {name,description} = service;
              return(
                <div className='border-b border-white/20 h-[146px] mb-[38px] flex' key={index}>
                  <div className='max-w-[476px]'>
                    <h4 className='text-[20px] tracking-wider font-primary font-bold mb-2'>{name}</h4>
                    <p className='font-secondary loading-tight'>{description}</p>
                  </div>
                </div>
              )
            })}
          </motion.div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Services;
