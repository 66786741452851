import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import {motion} from 'framer-motion';
import {fadeIn} from '../variants';

const About = () => {
  const [ref,inView] = useInView({
    threshold: 0.5,
  })
  return (
  <section className='section' id='about' ref={ref}>
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
        <motion.div variants={fadeIn('right',0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}} className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top shadow-md shadow-inner'></motion.div>
        <motion.div className='flex-1' variants={fadeIn('left',0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}} >
          <h2 className='h2 text-accent'>About Me.</h2>
          <h3 className='h3 mb-4'>A full stack software engineer with around 10 years of IT industry experience currently working as a Lead Engineer.</h3>
          <p className='mb-6'>
            Good at Frontend Development, Backend Development, Test Automation & Devops
          </p>
          <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'> 
                {
                  inView ? 
                  <CountUp start={0} end={10} duration={3} /> :
                  null
                }  
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Years of <br />
                Experience
              </div>
            </div>
            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'> 
                {
                  inView ? 
                  <CountUp start={0} end={3} duration={3} /> :
                  null
                }  
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Speaking <br />
                Languages
              </div>
            </div>
            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'> 
                {
                  inView ? 
                  <CountUp start={0} end={20} duration={3} /> :
                  null
                }  
                +
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Associated <br />
                Projects
              </div>
            </div>
          </div>
          <div className='flex gap-x-8 items-center'>
            <a href='https://www.linkedin.com/in/athuls1993/' target="_blank" >
              <button className='btn btn-lg'>Connect Me via Linkedin</button>
            </a>
            <a href='https://github.com/athulshere' className='text-gradient btn-link' target="_blank"> 
              Few of My Public Projects
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  </section>
  );
};

export default About;
