import React from 'react';
import Image from '../assets/athul.png';
import {FaGithub,FaYoutube,FaDribbble, FaLinkedin , FaInstagram} from 'react-icons/fa';
import {TypeAnimation} from 'react-type-animation';
import {motion} from 'framer-motion';
import {fadeIn} from '../variants';

const Banner = () => {
  return <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
        <div className='flex-1 font-secondary lg-:text-left'>
          <motion.h1 variants={fadeIn('up',0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[55px] font-bold leading-[0.8] lg-text-[110px]'>
            ATHUL <span>SATHEESH</span>
          </motion.h1>
          <motion.div variants={fadeIn('up',0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
            <span className='text-white mr-4'>I can</span>
            <TypeAnimation sequence={[
              'Design',
              700,
              'Develop',
              700,
              'Test',
              700,
              'Deploy',
              700,
            ]} speed={50} 
            className='text-accent'
            wrapper='span'
            repeat={Infinity}
            />
          </motion.div>
          <motion.p className='mb-8 max-w-lg mx-auto lg:mx-0' variants={fadeIn('up',0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} >
             A Full Stack Software Engineer who has hands on in Devlopment, Testing , Deployment and Designing of applications for both web, desktop and mobile.
          </motion.p>
          <motion.div className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'  variants={fadeIn('up',0.6)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} >
            <a href='#contact'>
            <button className='btn btn-lg'>
              Contact Me
            </button>
            </a>
            <a className='text-gradient btn-link' href='#'>My Portfolio</a>
          </motion.div>
          {/* <div className='flex text-[40px] gap-x-6 max-w-max mx-auto lg-max-0'> */}
          <motion.div className='flex items-start space-x-6 text-[40px]' variants={fadeIn('up',0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} >
            <a href='https://www.linkedin.com/in/athuls1993/' target="_blank">
              <FaLinkedin />
            </a>
            <a href='https://github.com/athulshere' target="_blank">
              <FaGithub />
            </a>
            <a href='https://www.instagram.com/athulskp/' target="_blank">
              <FaInstagram />
            </a>
          </motion.div>
        </div>
        <motion.div className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]' variants={fadeIn('down',0.5)} initial="hidden" whileInView={'show'} >
          {/* <img    className="w-full h-auto object-cover opacity-75 mix-blend-multiply"
           src={Image} alt=''></img> */}
           {/* <div
  className="flex-1 bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top shadow-md shadow-inner"
  style={{
    backgroundImage: `url(${Image})`,
    opacity: 0.7,
    transform: 'none',
  }}
></div> */}
<div
  className="flex-1 bg-cover bg-no-repeat h-[600px] mix-blend-lighten bg-top shadow-md shadow-inner rounded"
  style={{
    backgroundImage: `url(${Image})`,
    opacity: 1,
    transform: 'none'
  }}
></div>
        </motion.div>
      </div>
    </div>  
  </section>;
};

export default Banner;
